import React from "react";
import EVOAlertComponent from "../../../components/Abstract/EvoAlertComponent";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

import { CommonLoading } from "react-loadingg";
import Moment from "moment";

import Modal from "react-modal";
import { ApiCallerHelper } from "../../../api/apiCallerHelper";
import { RedirectHelper } from "../../../common/redirectHelper";
import EvoBaseComponent from "../../../components/Abstract/EvoBaseComponent";

class SupplierInstance extends EvoBaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      details: [],
      meter: [],
      sidata: [],
      dates: [],
      name: "",
      lastname: "",
      address: "",
      phone: "",
      city: "",
      mpower: "",
      afm: "",
      showModal: false,
      option: "",
      dateModal: "2001-01-01",
      reasonModal: "",
      stopMeterCount: "",
      phoneNumberModal: "",
      fullNameModal: "",
    };

    this.changeName = this.changeName.bind(this);
    this.changeLastName = this.changeLastName.bind(this);
    this.changePhone = this.changePhone.bind(this);
    this.changeAddress = this.changeAddress.bind(this);
    this.changeCity = this.changeCity.bind(this);
    this.changePower = this.changePower.bind(this);
    this.changeAFM = this.changeAFM.bind(this);

    this.updateDate = this.updateDate.bind(this);
    this.updateReason = this.updateReason.bind(this);
    this.updateFullName = this.updateFullName.bind(this);
    this.updatePhoneNumber = this.updatePhoneNumber.bind(this);
  }

  componentDidMount() {
    super.componentDidMount(this.initializeComponent);
  }

  initializeComponent = async () => {
    const profileId = this.props.match.params.id;
    let result = await ApiCallerHelper.Suppliers.getSuppliersProfile(profileId);
    result = result.data;
    this.setState({
      details: result.details,
      meter: result.meter,
      dates: result.dates,
      name: result.sidata.name,
      lastname: result.sidata.lastname,
      phone: result.sidata.phone,
      address: result.sidata.address,
      city: result.sidata.city,
      mpower: result.sidata.mpower,
      mcount: result.sidata.mcount,
      afm: result.sidata.afm,
      stopMeterCount: result.sidata.stopMeterCount,
      restartDate: result.sidata.restartDate,
      restartMeterCount: result.sidata.restartMeterCount,
      stopRepresentDate: result.dates.dateCeaseRepresentation,
      stopRepresentMeterCount: result.sidata.stopRepresentMeterCount,
      stopDate: result.sidata.stopDate,
    });
  };

  openRequest = async (option) => {
    const handle = this.props.match.params.id;
    let checkDataResultAsync = await ApiCallerHelper.Suppliers.checkData(
      handle
    );
    if (checkDataResultAsync.data) {
      this.setState({
        showModal: true,
        option: option,
      });
    } else {
      EVOAlertComponent.Alert({
        message:
          "Θα πρέπει να έχετε συμπληρώσει τα στοιχεία του καταναλωτή πριν κάνετε κάποιο αίτημα.",
        titleMessage: "Προσοχή!",
      });
    }
  };

  closeRequest() {
    this.setState({
      showModal: false,
      option: "",
    });
  }

  updateDate(evt) {
    this.setState({
      dateModal: evt.target.value,
    });
  }

  updateReason(evt) {
    this.setState({
      reasonModal: evt.target.value,
    });
  }

  updateFullName(evt) {
    this.setState({
      fullNameModal: evt.target.value,
    });
  }

  updatePhoneNumber(evt) {
    this.setState({
      phoneNumberModal: evt.target.value,
    });
  }

  sendRequest = async (evt) => {
    let result = await ApiCallerHelper.Suppliers.postSuppliersRequest({
      option: this.state.option,
      dateModal: this.state.dateModal,
      reasonModal: this.state.reasonModal,
      SerialNumber: this.state.details.SerialNumber,
      fullNameModal: this.state.fullNameModal,
      phoneNumberModal: this.state.phoneNumberModal,
    });

    this.setState({
      showModal: false,
      showRequestNotif: 1,
    });

    var self = this;
    setTimeout(function () {
      self.setState({
        showRequestNotif: 0,
      });
    }, 2500);
  };

  SaveActionType = {
    SaveData: "SaveData",
    Succession: "Succession",
  };

  saveData = async (option) => {
    let promptResult = await EVOAlertComponent.Prompt({});
    if (promptResult.isConfirmed === true) {
      if (option == this.SaveActionType.SaveData) {
        await ApiCallerHelper.Suppliers.postSuppliersSavedata({
          serialNumber: this.state.details.SerialNumber,
          name: this.state.name,
          lastName: this.state.lastname,
          phoneNumber: this.state.phone,
          address: this.state.address,
          city: this.state.city,
          meterPower: this.state.mpower,
          meterType: this.state.meter.type,
          afm: this.state.afm,
          constype: this.state.details.constype,
        });
        RedirectHelper.RedirectTo.reloadPageFromCache();
      } else if (option == this.SaveActionType.Succession) {
        await ApiCallerHelper.Suppliers.postSuppliersSuccession({
          serialNumber: this.state.details.SerialNumber,
          name: this.state.name,
          lastName: this.state.lastname,
          phoneNumber: this.state.phone,
          address: this.state.address,
          city: this.state.city,
          meterPower: this.state.mpower,
          meterType: this.state.meter.type,
          afm: this.state.afm,
          constype: this.state.details.constype,
        });
        RedirectHelper.RedirectTo.reloadPageFromCache();
      }
    }
  };

  changeName(evt) {
    this.setState({
      name: evt.target.value,
    });
  }

  changeLastName(evt) {
    this.setState({
      lastname: evt.target.value,
    });
  }

  changePhone(evt) {
    this.setState({
      phone: evt.target.value,
    });
  }

  changeAddress(evt) {
    this.setState({
      address: evt.target.value,
    });
  }

  changeCity(evt) {
    this.setState({
      city: evt.target.value,
    });
  }

  changePower(evt) {
    this.setState({
      mpower: evt.target.value,
    });
  }

  changeAFM(evt) {
    this.setState({
      afm: evt.target.value,
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <>
          <GenericHeader />
          <CommonLoading />
        </>
      );
    } else {
      return (
        <>
          <GenericHeader />
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  {this.state.showRequestNotif ? (
                    <CardHeader className="border-0">
                      <Row className="align-items-center">
                        <div className="col">
                          <div
                            className="alert alert-success"
                            id={"showRequestNotif"}
                            role="alert"
                            style={{ display: "block" }}
                          >
                            Το αίτημα στάλθηκε με επιτυχία.
                          </div>
                        </div>
                      </Row>
                    </CardHeader>
                  ) : (
                    <></>
                  )}
                  <CardHeader
                    className={
                      this.state.details.completed
                        ? "bg-red border-0"
                        : "bg-white border-0"
                    }
                  >
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">
                          Στοιχεία Αιτήματος Εκπροσώπησης
                        </h3>
                        {this.state.details.completed ? (
                          <h3 className="mb-0">ΔΙΑΚΟΠΗ</h3>
                        ) : (
                          <> </>
                        )}
                      </Col>
                      <Col className="text-right" xs="12">
                        <Button
                          color="success"
                          size="sm"
                          onClick={() => {
                            this.saveData(this.SaveActionType.SaveData);
                          }}
                        >
                          Αποθήκευση αλλαγών
                        </Button>
                        {!this.state.details.completed ? (
                          <>
                            <Button
                              color="danger"
                              size="sm"
                              onClick={() => {
                                this.saveData(this.SaveActionType.Succession);
                              }}
                            >
                              Διαδοχή
                            </Button>
                            <Button
                              color="danger"
                              onClick={() => {
                                this.openRequest("stop");
                              }}
                              size="sm"
                            >
                              Αίτημα Διακοπής
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              color="success"
                              onClick={() => {
                                this.openRequest("restart");
                              }}
                              size="sm"
                            >
                              Αίτημα Επανενεργοποίησης
                            </Button>
                            <Button
                              color="warning"
                              onClick={() => {
                                this.openRequest("remove");
                              }}
                              size="sm"
                            >
                              Αίτημα Παύσης Εκπροσώπησης
                            </Button>
                          </>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Βασικες πληροφοριες
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="1">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Α/Α
                              </label>
                              <Input
                                className="form-control-alternative"
                                Value={this.state.details.SerialNumber}
                                id="input-username"
                                placeholder="Α/Α"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Αριθμός ΗΚΑΣΠ
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                Value={this.state.details.Ikasp}
                                placeholder="Αριθμός ΗΚΑΣΠ"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Ημερομηνία Υποβολής Αιτήματος
                              </label>
                              <Input
                                className="form-control-alternative"
                                Value={this.state.dates.dateSubmit}
                                id="input-email"
                                placeholder="Ημερομηνία Έναρξης"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Ημερομηνία Ενεργοποίησης
                              </label>
                              <Input
                                className="form-control-alternative"
                                Value={this.state.dates.activation}
                                id="input-username"
                                placeholder="Ημερομηνία Ενεργοποίησης"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Ημερομηνία Αποδοχής
                              </label>
                              <Input
                                className="form-control-alternative"
                                Value={this.state.dates.dateApproved}
                                id="input-username"
                                placeholder="Ημερομηνία Ενεργοποίησης"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <hr></hr>
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Ημερομηνία Διαδοχής
                              </label>
                              <Input
                                className="form-control-alternative"
                                Value={this.state.dates.dateSuccession}
                                id="input-username"
                                placeholder="Ημερομηνία Διαδοχής"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Ένδειξη Μετρητή (διαδοχή)
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                Value={this.state.mcount}
                                placeholder="Ένδειξη Μετρητή (διαδοχή)"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label className="form-control-label">
                                Ημερομηνία Διακοπής
                              </label>
                              <Input
                                className="form-control-alternative"
                                Value={this.state.stopDate}
                                placeholder="Ημερομηνία Διακοπής"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label className="form-control-label">
                                Ένδειξη μετρητή (διακοπή)
                              </label>
                              <Input
                                className="form-control-alternative"
                                Value={this.state.stopMeterCount}
                                placeholder=" Ένδειξη μετρητή (διακοπή)"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label className="form-control-label">
                                Ημερομηνία Επανενεργοποίησης
                              </label>
                              <Input
                                className="form-control-alternative"
                                Value={this.state.restartDate}
                                placeholder="Ημερομηνία επανενεργοποίησης"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label className="form-control-label">
                                Ένδειξη μετρητή (επανενεργοποίησης)
                              </label>
                              <Input
                                className="form-control-alternative"
                                Value={this.state.restartMeterCount}
                                placeholder="Ένδειξη μετρητή (επανενεργοποίησης)"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label className="form-control-label">
                                Ημερομηνία Παύσης Εκπροσώπησης
                              </label>
                              <Input
                                className="form-control-alternative"
                                Value={this.state.stopRepresentDate}
                                placeholder="Ημερομηνία Παύσης Εκπροσώπησης"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="4">
                            <FormGroup>
                              <label className="form-control-label">
                                Ένδειξη μετρητή (Παυσης Εκπροσώπησης)
                              </label>
                              <Input
                                className="form-control-alternative"
                                Value={this.state.stopRepresentMeterCount}
                                placeholder="Ένδειξη μετρητή (Παυσης Εκπροσώπησης)"
                                type="text"
                                disabled
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>

                      <hr className="my-4" />
                      <div className="pl-lg-4">
                        <h6 className="heading-small text-muted mb-4">
                          Στοιχεια Καταναλωτη
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Όνομα Καταναλωτή
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  Value={this.state.name}
                                  placeholder="Όνομα Καταναλωτή"
                                  type="text"
                                  onChange={this.changeName}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Επίθετο Καταναλωτή
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  Value={this.state.lastname}
                                  placeholder="Επίθετο Καταναλωτή"
                                  type="text"
                                  onChange={this.changeLastName}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  ΑΦΜ Καταναλωτή
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  Value={this.state.afm}
                                  placeholder="ΑΦΜ Καταναλωτή"
                                  type="text"
                                  onChange={this.changeAFM}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Τηλέφωνο Καταναλωτή
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  Value={this.state.phone}
                                  placeholder="Τηλέφωνο Καταναλωτή"
                                  type="text"
                                  onChange={this.changePhone}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Διεύθυνση
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  Value={this.state.address}
                                  placeholder="Διεύθυνση"
                                  type="text"
                                  onChange={this.changeAddress}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Πόλη
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  Value={this.state.city}
                                  placeholder="Πόλη"
                                  type="text"
                                  onChange={this.changeCity}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Κατηγορία Καταναλωτή
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  Value={this.state.details.constype}
                                  placeholder="Κατηγορία"
                                  type="text"
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <hr className="my-4" />
                      <div className="pl-lg-4">
                        <h6 className="heading-small text-muted mb-4">
                          Στοιχεια Μετρητη
                        </h6>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Τύπος Μετρητή
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  Value={this.state.meter.Type}
                                  placeholder="Τύπος Μετρητή"
                                  type="text"
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Σειριακός Αριθμός
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  Value={this.state.meter.SerialNumber}
                                  placeholder="Σειριακός Αριθμός"
                                  type="text"
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Τιμολογούμενη Δυναμικότητα Μετρητή
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  Value={this.state.mpower}
                                  placeholder="Τιμολογούμενη Δυναμικότητα Μετρητή"
                                  type="text"
                                  onChange={this.changePower}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="2">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-email"
                                >
                                  Κατάσταση Μετρητή
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-email"
                                  value={
                                    this.state.meter.Active
                                      ? "Ενεργός"
                                      : "Μη Ένεργος"
                                  }
                                  placeholder="Κατάσταση Μετρητή"
                                  type="text"
                                  onChange={this.changePower}
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <hr className="my-4" />
                          <Row>
                            <Col lg="4"> </Col>
                            <Col lg="4">
                              <p style={{ width: "60%", textAlign: "center" }}>
                                Υπόμνημα Μετρητή-ΔΩΔ
                              </p>
                              <Table
                                className="align-items-center table-flush"
                                responsive
                              >
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col">Μετρητης</th>
                                    <th scope="col">KWh</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                  <th scope="row">G4 (Οικιακοί)</th>
                                    <td>53.21</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">G4 (Εμπορικοί)</th>
                                    <td>68.4</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">G6 (Οικιακοί, Εμπορικοί)</th>
                                    <td>114</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">G10</th>
                                    <td>182.4</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">G16</th>
                                    <td>285</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">G25</th>
                                    <td>456</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>

          <Modal
            isOpen={this.state.showModal}
            contentLabel="Request Modal"
            style={{
              overlay: {},
              content: {
                position: "absolute",
                top: "40%",
                left: "30%",
                marginTop: "-50px",
                marginLeft: "-30px",
                width: "45%",
                height: "50%",
              },
            }}
          >
            {this.state.option == "stop" ? (
              <>
                <label className="form-control-label" htmlFor="input-username">
                  Ονοματεπώνυμο *
                </label>
                <Input
                  className="form-control-alternative"
                  id="full-name"
                  placeholder="Ονοματεπώνυμο"
                  onChange={(evt) => this.updateFullName(evt)}
                  type="text"
                  required
                />
                <p></p>
                <label className="form-control-label" htmlFor="input-username">
                  Τηλέφωνο *
                </label>
                <Input
                  className="form-control-alternative"
                  id="full-name"
                  placeholder="Τηλέφωνο"
                  onChange={(evt) => this.updatePhoneNumber(evt)}
                  type="tel"
                />
                <p></p>
                <label className="form-control-label" htmlFor="input-username">
                  Επιθυμητή ημερομηνία διακοπής
                </label>
                <Input
                  className="form-control-alternative"
                  id="date-stop"
                  onChange={(evt) => this.updateDate(evt)}
                  type="date"
                />
                <p></p>
                <label className="form-control-label" htmlFor="input-username">
                  Λόγος διακοπής *
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="Λόγος διακοπής"
                  id="reason-stop"
                  onChange={(evt) => this.updateReason(evt)}
                  type="select"
                >
                  <option value=""></option>
                  <option value="Αίτημα Χρήστη Διανομής κατόπιν αιτήματος Τελικού Πελάτη">
                    Αίτημα Χρήστη Διανομής κατόπιν αιτήματος Τελικού Πελάτη
                  </option>
                  <option value="Αίτημα Χρήστη Διανομής">
                    Αίτημα Χρήστη Διανομής
                  </option>
                </Input>
                <p></p>
                <Button
                  color="success"
                  onClick={() => {
                    this.sendRequest();
                  }}
                  size="sm"
                  disabled={
                    !this.state.fullNameModal ||
                    !this.state.phoneNumberModal ||
                    this.state.reasonModal == ""
                  }
                >
                  Αποστολή Αιτήματος
                </Button>
              </>
            ) : (
              <></>
            )}

            {this.state.option == "restart" ? (
              <>
                <label className="form-control-label" htmlFor="input-username">
                  Επιθυμητή ημερομηνία επανενεργοποίησης
                </label>
                <Input
                  className="form-control-alternative"
                  id="date-restart"
                  onChange={(evt) => this.updateDate(evt)}
                  type="date"
                />
                <p></p>
                <Button
                  color="success"
                  onClick={() => {
                    this.sendRequest();
                  }}
                  size="sm"
                >
                  Αποστολή Αιτήματος
                </Button>
              </>
            ) : (
              <></>
            )}

            {this.state.option == "remove" ? (
              <>
                <label className="form-control-label" htmlFor="input-username">
                  Επιθυμητή ημερομηνία παύσης
                </label>
                <Input
                  className="form-control-alternative"
                  id="date-remove"
                  onChange={(evt) => this.updateDate(evt)}
                  type="date"
                />
                <p></p>
                <Button
                  color="success"
                  onClick={() => {
                    this.sendRequest();
                  }}
                  size="sm"
                >
                  Αποστολή Αιτήματος
                </Button>
              </>
            ) : (
              <></>
            )}

            <Button
              color="primary"
              onClick={() => {
                this.closeRequest();
              }}
              size="sm"
            >
              Κλείσιμο
            </Button>
          </Modal>
        </>
      );
    }
  }
}

export default SupplierInstance;
