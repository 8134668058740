import {
  get,
  getAsync,
  post,
  postAsync,
  put,
  putAsync,
  del,
  deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
} from "./apiCallerHelper.core";

import { getUserCurrentAsync } from "./apiCallerHelper.auth";

import {
  getAllSuppliers,
  checkData,
  getSuppliersProfile,
  postSuppliersRequest,
  postSuppliersSavedata,
  postSuppliersSuccession,
  getPastInstances,
  checkDataPast,
  getSupplierConnection,
} from "./apiCallerHelper.suppliers";

import { getExportConnections } from "./apiCallerHelper.export";

export const ApiCallerHelper = {
  hasInitialisedToken: () => {
    return hasInitialisedToken();
  },
  Common: {
    get,
    post,
    delete: del,
  },
  Auth: {
    getUserCurrentAsync: getUserCurrentAsync,
  },
  Users: {
    getCurrentAsync: getUserCurrentAsync,
  },
  Suppliers: {
    getAllSuppliers: getAllSuppliers,
    checkData: checkData,
    checkDataPast: checkDataPast,
    getSuppliersProfile: getSuppliersProfile,
    postSuppliersRequest: postSuppliersRequest,
    postSuppliersSavedata: postSuppliersSavedata,
    postSuppliersSuccession: postSuppliersSuccession,
    getPastInstances: getPastInstances,
    getSupplierConnection: getSupplierConnection,
  },
  Export: {
    getExportConnections: getExportConnections,
  },
};
