import Login from "views/examples/Login.js";
import Logout from "views/examples/Logout.js";
import SupplierIndex from "views/examples/supplier/supplierIndex.js";
import NewInstance from "views/examples/supplier/newInstance.js";
import NewResult from "views/examples/supplier/newResult";
import SupplierInstance from "views/examples/supplier/supplierInstance.js";
import SupplierPastInstance from "views/examples/supplier/supplierPastInstance.js";
import SupplierDocuments from "views/examples/supplier/supplierDocuments.js";
import SupplierProfile from "views/examples/supplier/supplierProfile.js";
import SupplierSettings from "views/examples/supplier/supplierSettings.js";
import SupplierSupport from "views/examples/supplier/supplierSupport.js";
import Succession from "views/examples/supplier/Succession.js";
import StopRequest from "views/examples/supplier/stopRequest.js";
import RequestResult from "views/examples/supplier/requestResult.js";
import SupplierRequests from "views/examples/supplier/supplierRequests.js";
import SupplierHistory from "views/examples/supplier/supplierHistory.js";
import SupplierConnections from "views/examples/supplier/supplierConnections";

var routes = [
  {
    path: "/index",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink", 
    component: SupplierIndex,
    layout: "/supplier",
  },
  {
    path: "/newinstance",
    name: "Κατοχύρωση Νέας",
    icon: "ni ni-circle-08 text-pink",
    component: NewInstance,
    layout: "/supplier",
  },
  {
    path: "/result/:id",
    name: "Αποτέλεσμα Κατοχύρωσης",
    icon: "ni ni-circle-08 text-pink",
    component: NewResult,
    layout: "/supplier",
  },
  {
    path: "/reqresult/:id",
    name: "Αποτέλεσμα Κατοχύρωσης",
    icon: "ni ni-circle-08 text-pink",
    component: RequestResult,
    layout: "/supplier",
  },
  {
    path: "/requests",
    name: "Αιτήσεις",
    icon: "ni ni-circle-08 text-pink",
    component: SupplierRequests,
    layout: "/supplier",
  },
  {
    path: "/connections",
    name: "Συνδέσεις σε εκκρεμότητα",
    icon: "ni ni-circle-08 text-pink",
    component: SupplierConnections,
    layout: "/supplier",
  },
  {
    path: "/history",
    name: "Αιτήσεις",
    icon: "ni ni-circle-08 text-pink",
    component: SupplierHistory,
    layout: "/supplier",
  },
  {
    path: "/succession",
    name: "Αιτήσεις",
    icon: "ni ni-circle-08 text-pink",
    component: Succession,
    layout: "/supplier",
  },
  {
    path: "/stoprequest",
    name: "Αιτήσεις",
    icon: "ni ni-circle-08 text-pink",
    component: StopRequest,
    layout: "/supplier",
  },
  {
    path: "/instance/:id",
    name: "Αίτηση",
    icon: "ni ni-circle-08 text-pink",
    component: SupplierInstance,
    layout: "/supplier",
  },
  {
    path: "/pastinstance/:id",
    name: "Παλαιότερη Αίτηση",
    icon: "ni ni-circle-08 text-pink",
    component: SupplierPastInstance,
    layout: "/supplier",
  },
  {
    path: "/documents",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: SupplierDocuments,
    layout: "/supplier",
  },
  {
    path: "/profile",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: SupplierProfile,
    layout: "/supplier",
  },
  {
    path: "/settings",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: SupplierSettings,
    layout: "/supplier",
  },
  {
    path: "/support",
    name: "Αρχική",
    icon: "ni ni-circle-08 text-pink",
    component: SupplierSupport,
    layout: "/supplier",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "ni ni-key-25 text-info",
    component: Logout,
    layout: "/auth",
  },
];

export default routes;
