import React from "react";
import { Card, CardBody, Form, Container, Row, Col } from "reactstrap";
import GenericHeader from "components/Headers/GenericHeader.js";

class NewResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      user: [],
      result: 0,
    };
  }

  componentDidMount() {
    const handle = this.props.match.params.id;
    this.setState({
      result: handle,
    });
  }

  render() {
    return (
      <>
        <GenericHeader />
        <Container className="mt--5" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          {this.state.result == 1 ? (
                            <Col xs="8">
                              <h3 className="mb-0" style={{ color: "green" }}>
                                Το αίτημα του Χρήστη Διανομής για τροφοδότηση
                                του σημείου παράδοσης είναι πλήρες και αποδεκτό.
                              </h3>
                            </Col>
                          ) : (
                            <>
                              {this.state.result == 0 ? (
                                <Col xs="8">
                                  <h3 className="mb-0" style={{ color: "red" }}>
                                    Το αίτημα του Χρήστη Διανομής για
                                    τροφοδότηση του σημείου παράδοσης δεν μπορεί
                                    να γίνει αποδεκτό, καθώς δεν βρέθηκε ο ΗΚΑΣΠ
                                    που δηλώσατε.
                                  </h3>
                                </Col>
                              ) : (
                                <Col xs="12">
                                  <h3
                                    className="mb-0"
                                    style={{ color: "orange" }}
                                  >
                                    Ο ΗΚΑΣΠ που δηλώσατε, εκπροσωπείται από
                                    άλλον Χρήστη Διανομής. Θα εφαρμοστεί η
                                    διαδικασία περί ληξιπρόθεσμων οφειλών
                                    σύμφωνα με τον Κώδικα Διανομής και θα
                                    ενημερωθείτε άμεσα.
                                  </h3>
                                </Col>
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default NewResult;
