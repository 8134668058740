import React from "react";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import "../../../assets/css/datatables.css";

class SupplierConnections extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      userDetails: [],
      instances: [],
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            userDetails: result,
          });
        });

      axios
        .get(
          process.env.REACT_APP_API_LINK +
            "/api/suppliers/get_connections_view/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          const instances = res.data.map((instance) => ({
            ...instance,
            Actions: (
              <React.Fragment>
                <Button
                  tag="a"
                  href={`/supplier/connection/${instance.InstanceId}`}
                  color="primary"
                  size="sm"
                >
                  Στοιχεία Σύνδεσης
                </Button>
              </React.Fragment>
            ),
          }));

          this.datas = {
            columns: [
              {
                label: "ΗΚΑΣΠ ▼",
                field: "Ikasp",
                sort: "asc",
                width: 150,
              },
              {
                label: "Ημερομηνία ▼",
                field: "Date",
                sort: "asc",
                width: 100,
              },
              {
                label: "Τοποθεσια ▼",
                field: "City",
                sort: "asc",
                width: 150,
              },
              // {
              //   label: "ΠΡΟΣ ▼",
              //   field: "Name",
              //   sort: "asc",
              //   width: 150,
              // },
            ],
            rows: instances,
          };

          this.setState({ instances });
        });
    }
  }

  render() {
    return (
      <>
        <GenericHeader />
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">
                        Αιτήματα εκπροσώπησης σε εκκρεμότητα
                      </h3>
                    </div>
                    <div className="col text-right"></div>
                  </Row>
                  <MDBDataTable
                    bordered
                    exportToCSV
                    hover
                    strip
                    responsive
                    data={this.datas}
                    id="mdbdttable"
                    entriesOptions={[10, 25, 50, 100, 500, 1000]}
                    entries={10}
                    noDataComponent="Δεν υπάρχουν δεδομένα"
                    searchLabel="Αναζήτηση"
                    sNext="Suivant"
                    paginationLabel={["Προηγούμενο", "Επόμενο"]}
                    infoLabel={["Εμφανίζονται", "έως", "από", "καταχωρήσεις"]}
                    entriesLabel={"Εμφάνιση Αιτημάτων ανά: "}
                  />
                </CardHeader>
              </Card>
            </Col>
          </Row>

          <div class="push"></div>
        </Container>
      </>
    );
  }
}

export default SupplierConnections;
