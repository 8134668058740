import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import GenericHeader from "components/Headers/GenericHeader.js";

class NewInstance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      user: [],
      roles: [],
      ikasp: "",
      afm: "",
      removeActions: 0,
      afmError: false,
      ikaspError: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
    }
  }

  validateForm() {
    const isIkaspValid = /^\d{14}$/.test(this.state.ikasp);
    const isAfmValid = /^\d{9}$/.test(this.state.afm);

    return isIkaspValid && isAfmValid;
  }

  updateIkasp(evt) {
    const enteredIkasp = evt.target.value;
    const isFourteenDigitNumber = /^\d{14}$/.test(enteredIkasp);

    this.setState({
      ikasp: enteredIkasp,
      ikaspError: !isFourteenDigitNumber,
    });
  }

  updateAFM(evt) {
    const enteredAFM = evt.target.value;
    const isNineDigitNumber = /^\d{9}$/.test(enteredAFM);

    this.setState({
      afm: enteredAFM,
      afmError: !isNineDigitNumber,
    });
  }

  setSerial = (evt) => {
    this.setState({ removeActions: 1 });

    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/suppliers/newinstance/", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
        }),
        body: JSON.stringify({
          Ikasp: this.state.ikasp,
          AFM: this.state.afm,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          window.location.replace("/supplier/result/" + data.Result);
        });
    }
  };

  render() {
    return (
      <>
        <GenericHeader />
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Καταχώρηση νέου αιτήματος</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {!this.state.removeActions ? (
                        <Button
                          color="success"
                          onClick={(evt) => {
                            this.setSerial(evt);
                          }}
                          size="sm"
                          disabled={!this.validateForm()}
                        >
                          Καταχώρηση
                        </Button>
                      ) : (
                        <> </>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              ΗΚΑΣΠ
                            </label>
                            <Input
                              className={`form-control-alternative ${
                                this.state.ikaspError ? "is-invalid" : ""
                              }`}
                              id="input-username"
                              placeholder="ΗΚΑΣΠ"
                              type="text"
                              onChange={(evt) => this.updateIkasp(evt)}
                            />
                            {this.state.ikaspError && (
                              <div className="invalid-feedback">
                                Το ΗΚΑΣΠ πρέπει να αποτελείται από 14 ψηφία.
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              ΑΦΜ Καταναλωτή
                            </label>
                            <Input
                              className={`form-control-alternative ${
                                this.state.afmError ? "is-invalid" : ""
                              }`}
                              id="input-username"
                              placeholder="ΑΦΜ Καταναλωτή"
                              type="text"
                              onChange={(evt) => this.updateAFM(evt)}
                            />
                            {this.state.afmError && (
                              <div className="invalid-feedback">
                                Το ΑΦΜ πρέπει να αποτελείται από 9 ψηφία.
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      {this.state.user.consumerType == 2 ? (
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Επωνυμία Φορέα
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.user.companyName}
                                id="input-username"
                                placeholder=""
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <hr className="my-4" />
                        </Row>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default NewInstance;
