import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import GenericHeader from "components/Headers/GenericHeader.js";

class StopRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      user: [],
      roles: [],
      ikasp: "",
      //   afm: '',
      //   submit: true,
      removeActions: 0,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
    }
  }

  updateIkasp(evt) {
    this.setState({
      ikasp: evt.target.value,
    });

    //   if (this.state.afm != '') {
    //     this.setState({
    //       submit: false
    //     })
    //   }
  }

  // updateAFM(evt) {
  //   ;
  //   this.setState({
  //       afm: evt.target.value
  //   })

  //   if (this.state.ikasp != '') {
  //     this.setState({
  //       submit: false
  //     })
  //   }
  // }

  sendRequest = (evt) => {
    this.setState({ removeActions: 1 });

    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/suppliers/stoprequest/", {
        method: "POST",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
        }),
        body: JSON.stringify({
          Ikasp: this.state.ikasp,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          window.location.replace("/supplier/reqresult/" + data.Result);
        });
    }
  };

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Νέο αίτημα διακοπής</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      {!this.state.removeActions ? (
                        <Button
                          color="success"
                          onClick={(evt) => {
                            this.setSerial(evt);
                          }}
                          size="sm"
                          //   disabled={this.state.submit}
                        >
                          Υποβολή
                        </Button>
                      ) : (
                        <> </>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    {/* <h6 className="heading-small text-muted mb-4">
                      Βασικα στοιχεια
                    </h6> */}
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              ΗΚΑΣΠ
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder="ΗΚΑΣΠ"
                              type="text"
                              onChange={(evt) => this.updateIkasp(evt)}
                            />
                          </FormGroup>
                        </Col>
                        {/* <Col lg="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              ΑΦΜ Καταναλωτή
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-username"
                              placeholder="ΑΦΜ Καταναλωτή"
                              type="text"
                              onChange={evt => this.updateAFM(evt)}
                            />
                          </FormGroup>
                        </Col> */}
                      </Row>

                      {this.state.user.consumerType == 2 ? (
                        <Row>
                          <Col lg="4">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                              >
                                Επωνυμία Φορέα
                              </label>
                              <Input
                                className="form-control-alternative"
                                defaultValue={this.state.user.companyName}
                                id="input-username"
                                placeholder=""
                                type="text"
                              />
                            </FormGroup>
                          </Col>
                          <hr className="my-4" />
                        </Row>
                      ) : (
                        <p></p>
                      )}
                      {/* <br />
                      <br />
                      <br />
                      <Col lg="12">
                          <h3>Σημείωση :</h3>
                          <p><i>Θα πρέπει να υποβάλλεται...</i></p>
                      </Col> */}
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default StopRequest;
