import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from "axios";
import { MDBDataTable } from "mdbreact";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import "moment/locale/el";

import "../../../assets/css/datatables.css";

class SupplierRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      userDetails: [],
      instances: [],
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            userDetails: result,
          });
        });

      axios
        .get(
          process.env.REACT_APP_API_LINK +
            "/api/suppliers/requests/supppending/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          const instances2 = res.data;
          var self = this;

          instances2.forEach(function (part, index) {
            this[index].Buttons = (
              <React.Fragment>
                <Button
                  color="primary"
                  href={"/supplier/instance/" + this[index].InstanceId}
                  size="sm"
                >
                  Στοιχεία Αίτησης
                </Button>
              </React.Fragment>
            );
          }, instances2);
          this.datas = {
            columns: [
              {
                label: "ΗΚΑΣΠ ▼",
                field: "Ikasp",
                sort: "asc",
                width: 150,
              },
              {
                label: "Αιτων ▼",
                field: "Supplier",
                sort: "asc",
                width: 150,
              },
              {
                label: "Ειδος Αιτηματος ▼",
                field: "ReqKind",
                sort: "asc",
                width: 150,
              },
              {
                label: "Hμ. Αιτηματος ▼",
                field: "ReqDate",
                sort: "asc",
                width: 150,
              },
              {
                label: "Επιλεγμενη Ημ. ▼",
                field: "SelDate",
                sort: "asc",
                width: 150,
              },
              {
                label: "Λoγος Διακοπης ▼",
                field: "Reason",
                sort: "asc",
                width: 150,
              },
            ],
            rows: res.data,
          };

          const instances = res.data;
          this.setState({ instances });
        });
    }
  }

  render() {
    return (
      <>
        <GenericHeader />

        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Λοιπά Αιτήματα</h3>
                    </div>
                    <div className="col text-right">
                      {/* <Button
                        color="primary"
                        href={"/supplier/instances"}
                        size="sm"
                      >
                        Προβολή όλων
                        
                      </Button>
                      <Button
                        color="success"
                        href={"/supplier/newinstance"}
                        size="sm"
                      >
                        Κατοχύρωση νέας
                        
                      </Button> */}
                    </div>
                  </Row>
                  <MDBDataTable
                    bordered
                    exportToCSV
                    hover
                    responsive
                    strip
                    data={this.datas}
                    id="mdbdttable"
                    entriesOptions={[10, 25, 50, 100, 500, 1000]}
                    entries={10}
                    noDataComponent="Δεν υπάρχουν δεδομένα"
                    searchLabel="Αναζήτηση"
                    sNext="Suivant"
                    paginationLabel={["Προηγούμενο", "Επόμενο"]}
                    infoLabel={["Εμφανίζονται", "έως", "από", "καταχωρήσεις"]}
                    entriesLabel={"Εμφάνιση Αιτημάτων ανά: "}
                  />
                </CardHeader>
              </Card>
            </Col>
          </Row>

          <div class="push"></div>
        </Container>
      </>
    );
  }
}

export default SupplierRequests;
