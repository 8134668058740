import React from "react";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import { Button, Card, CardHeader, Container, Row, Col } from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import "../../../assets/css/datatables.css";

class SupplierIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      userDetails: [],
      instances: [],
    };
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/x-www-form-urlencoded",
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          this.setState({
            userDetails: result,
          });
        });

      axios
        .get(process.env.REACT_APP_API_LINK + "/api/suppliers/instances/", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((res) => {
          const instances2 = res.data;
          instances2.forEach(function (part, index) {
            this[index].Buttons = (
              <React.Fragment>
                <Button
                  color="primary"
                  href={"/supplier/instance/" + this[index].Id}
                  size="sm"
                >
                  Στοιχεία Αίτησης
                </Button>
              </React.Fragment>
            );
          }, instances2);
          instances2.forEach(function (part, index) {
            this[index].Stopped = (
              <React.Fragment>
                {this[index].Completed === "remove" ? (
                  <Button color="warning" size="sm">
                    Αίτημα Διακοπής
                  </Button>
                ) : this[index].Completed ? (
                  <Button color="danger" size="sm">
                    Σε Διακοπή
                  </Button>
                ) : (
                  <Button color="success" size="sm">
                    Ενεργή
                  </Button>
                )}
              </React.Fragment>
            );
          }, instances2);
          this.datas = {
            columns: [
              {
                label: "Αριθμος Αιτησης ▼",
                field: "SerialNumber",
                sort: "asc",
                width: 100,
              },
              {
                label: "ΗΚΑΣΠ ▼",
                field: "Ikasp",
                sort: "asc",
                width: 150,
              },
              {
                label: "Τοποθεσια ▼",
                field: "Location",
                sort: "asc",
                width: 150,
              },
              {
                label: "Κατασταση ▼",
                field: "Stopped",
                sort: "asc",
                width: 150,
              },
              {
                label: "Μεταβαση ▼",
                field: "Buttons",
                sort: "asc",
                width: 150,
              },
            ],
            rows: res.data,
          };

          const instances = res.data;
          this.setState({ instances });
        });
    }
  }

  telisExport() {
    if (localStorage.getItem("token")) {
      axios
        .get(
          process.env.REACT_APP_API_LINK + "/api/suppliers/export/connections/",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            responseType: "blob",
          }
        )
        .then((result) => {
          const url = window.URL.createObjectURL(new Blob([result.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `connections.xlsx`);

          document.body.appendChild(link);

          link.click();

          link.parentNode.removeChild(link);
        });
    }
  }

  render() {
    return (
      <>
        <GenericHeader />
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Συμβεβλημένες αιτήσεις</h3>
                    </div>
                    <div className="col text-right">
                      <Button
                        color="success"
                        onClick={() => this.telisExport()}
                        size="sm"
                      >
                        Εξαγωγή στοιχείων
                      </Button>
                      <Button
                        color="primary"
                        href={"/supplier/newinstance"}
                        size="sm"
                      >
                        Νέο αίτημα εκπροσώπησης
                      </Button>
                    </div>
                  </Row>
                  <MDBDataTable
                    bordered
                    exportToCSV
                    hover
                    strip
                    responsive
                    data={this.datas}
                    id="mdbdttable"
                    entriesOptions={[10, 25, 50, 100, 500, 1000]}
                    entries={10}
                    noDataComponent="Δεν υπάρχουν δεδομένα"
                    searchLabel="Αναζήτηση"
                    sNext="Suivant"
                    paginationLabel={["Προηγούμενο", "Επόμενο"]}
                    infoLabel={["Εμφανίζονται", "έως", "από", "καταχωρήσεις"]}
                    entriesLabel={"Εμφάνιση Αιτημάτων ανά: "}
                  />
                </CardHeader>
              </Card>
            </Col>
          </Row>

          <div class="push"></div>
        </Container>
      </>
    );
  }
}

export default SupplierIndex;
